import "../styles.scss";
import React from "react";
import PropTypes from "prop-types";
import { getIn } from "formik";
import _ from "lodash";
import { createClassNameString } from "../../utils";
import { FormFieldError } from "../FormFieldError/FormFieldError";
import { Dropdown } from "../../Dropdown";

export const FormSelect = ({
    name,
    title,
    subTitle,
    placeholder,
    customError,
    className,
    dropDownClassName,
    formikProps,
    // normally form fields do not error until after submission
    // because they have not been touched
    // setting this to true triggers the error after editing
    // for the first time
    triggerErrorOnChange = false,
    disabled,
    options,
    dataPrivate,
    filterOptions,
    // a list of other field names whose errors this field should subscribe to
    otherErrorNames = [],
    // see comment in src/component-library/FormFields/FormTextArea/FormTextArea.jsx
    onChangeCallBack,
}) => {
    const errorMessage = getIn(formikProps.errors, name);
    // get the other error messages
    const otherErrors = otherErrorNames
        .map((otherErrorName) => getIn(formikProps.errors, otherErrorName))
        .filter(Boolean);
    const isTouched = getIn(formikProps.touched, name);
    const value = _.get(formikProps.values, name);

    const handleChange = async (event) => {
        await formikProps.setFieldValue(name, event.target.value);

        if (!isTouched && triggerErrorOnChange) {
            await formikProps.setFieldTouched(name, true);
        }
        if (onChangeCallBack) {
            await onChangeCallBack(name, event.target.value);
        }
    };

    const selectedOption = options.find((item) => {
        return item.value === value;
    });

    return (
        <div className={createClassNameString(["FormField-container", className])}>
            {(title || subTitle) && (
                <div data-public className="FormField-header_container">
                    {title && <h3 className="FormField-title">{title}</h3>}
                    {subTitle && <h3 className="FormField-subTitle">{subTitle}</h3>}
                </div>
            )}
            <div>
                <span data-private={dataPrivate}>
                    <Dropdown
                        className={createClassNameString(["FormSelect", dropDownClassName])}
                        error={isTouched && (errorMessage || otherErrors?.length > 0)}
                        value={selectedOption}
                        options={options}
                        placeholder={placeholder}
                        onChange={handleChange}
                        isDisabled={disabled}
                        filterOptions={filterOptions}
                    />
                </span>
                {!customError && (errorMessage || otherErrors?.length > 0) && isTouched && (
                    <FormFieldError otherErrors={otherErrors}>{errorMessage}</FormFieldError>
                )}
                {/* lets the dev pick what is displayed below the text area */}
                {customError && customError(value, errorMessage, isTouched)}
            </div>
        </div>
    );
};

FormSelect.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    customError: PropTypes.func,
    formikProps: PropTypes.object,
    disabled: PropTypes.bool,
};
