// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import { NOT_ASSIGNED, transformMarkerData } from "../../../Patient/NinetyDayCareProgressGuide";
import { _90_DAY_CARE_PLAN } from "./index";
import PatientReferralInfo from "../../../../containers/Dashboard/Providers/PatientReferralInfo";
import { api } from "../../../../APIRequests";
import ActivityFeed from "./../ActivityFeed";
import { PatientProfile90DayProgress } from "../PatientProfile90DayProgress";
import Schedule90DayTask from "./../Schedule-90-day-task";
import { isAuthorized, isUserBehavioral } from "../../../../redux/actions/auth";
import ClinicalAssessmentCard from "./../ClinicalAssessmentCard";
import CarePlanCard__EXPERIMENTAL_GOALS_AND_OBJECTIVES__ENABLED from "./../CarePlanCard__EXPERIMENTAL_GOALS_AND_OBJECTIVES__ENABLED";
import CarePlanCard__EXPERIMENTAL_GOALS_AND_OBJECTIVES__DISABLED from "./../CarePlanCard__EXPERIMENTAL_GOALS_AND_OBJECTIVES__DISABLED";
import CarePlanCreation__EXPERIMENTAL_GOALS_AND_OBJECTIVES__DISABLED from "./CarePlanCreation__EXPERIMENTAL_GOALS_AND_OBJECTIVES__DISABLED";
import CarePlanCreation__EXPERIMENTAL_GOALS_AND_OBJECTIVES__ENABLED from "./CarePlanCreation__EXPERIMENTAL_GOALS_AND_OBJECTIVES__ENABLED";
import AdmitPatientPopup from "./../AdmitPatientPopup";
import AdmitPatient from "./../AdmitPatient";
import withRouter from "react-router-dom/es/withRouter";
import { OutreachAddCard, OutreachEntries } from "./Outreach";
import { connect } from "react-redux";
import { admin_cse, provider_case_manager, provider_peer } from "../../../../config/roles";
import FeatureGate from "../../../Common/FeatureGate/FeatureGate";
import { EXPERIMENTAL_GOALS_AND_OBJECTIVES } from "../../../../constants/featureFlags";
import { PATIENT_INACTIVATION_TYPE } from "../../../../constants/patient";

class MemberProfileTimeline extends Component {
    state = {
        markerData: [
            { status: "default", caption: "90 Day Care Plan" },
            { status: "default", caption: "Active 90 Day Care" },
            { status: "default", caption: "Ongoing Assessment Pending" },
        ],
        patientId: this.props.match.params.patientId,
        patientName:
            this.props.patientDetails.first_name + " " + this.props.patientDetails.last_name,
        practiceName: this.props.data["practiceName"],
        activities: this.props.data["activities"],
        showAdmitTo90DayCarePopup: false,
        showCarePlanPopup: false,
    };

    async componentDidMount() {
        if (!this.state.practiceName) {
            await this.getPracticeName(this.props.patientDetails);
        }
    }

    async getPracticeName(patientDetails) {
        const practiceName = await api.a5Screening.get_practice_name({
            queryParams: {
                practiceId: patientDetails.pcp_practice_id,
            },
        });
        this.setState({ practiceName: practiceName.message });

        const data = { ...this.props.data };
        data["practiceName"] = practiceName.message;
        this.props.updateState({ timelineData: data });
    }

    admitPatient = (formData, action) => {
        let data = { ...formData };
        let urlParams = { patientId: this.state.patientId };
        api.provider
            .begin_ninetyDays_care_plan({ data, urlParams })
            .then((response) => {
                this.setState({ showAdmitTo90DayCarePopup: false });
                const patientDetails = { ...this.props.patientDetails };
                patientDetails[_90_DAY_CARE_PLAN] = "not_started";
                patientDetails.status = "ACTIVE 90-DAY";
                const newState = { patientDetails: patientDetails };
                this.props.updateState(newState);
            })
            .catch((err) => console.log(err));
    };

    submitOutreachAttempt = (outreachAttempt) => {
        const urlParams = { patientId: this.state.patientId };
        const data = { outreach_attempt: outreachAttempt };

        api.provider
            .post_outreach_attempt({ data, urlParams })
            .then((response) => {
                const patientDetails = { ...this.props.patientDetails };
                patientDetails["outreach"] = patientDetails["outreach"] || {};
                patientDetails["outreach"][response.timestamp] = response.outreach_attempt;
                const newState = { patientDetails: patientDetails };
                this.props.updateState(newState);
            })
            .catch((err) => console.log(err));
    };

    viewOnlyClinicalAssessmentAccess = (role, isAssessmentCompleted) => {
        return isAuthorized(role) && isAssessmentCompleted;
    };

    render() {
        const patientDetails = this.props.patientDetails;

        if (patientDetails[_90_DAY_CARE_PLAN]) {
            transformMarkerData([...this.state.markerData], patientDetails[_90_DAY_CARE_PLAN]);
        }

        const show90DayProgress = patientDetails.hasOwnProperty("ninety_day_start_date");

        const showSchedule90DayTaskCard =
            (isAuthorized("provider:prescribe") || isAuthorized("provider:behavioral")) &&
            patientDetails[_90_DAY_CARE_PLAN] !== NOT_ASSIGNED &&
            patientDetails.initial_clinical_assessment_complete === true;

        const showInitialClinicalAssessmentCard =
            isUserBehavioral() ||
            this.viewOnlyClinicalAssessmentAccess(
                provider_case_manager,
                patientDetails.initial_clinical_assessment_complete || false,
            ) ||
            this.viewOnlyClinicalAssessmentAccess(
                provider_peer,
                patientDetails.initial_clinical_assessment_complete || false,
            );

        const showOutcomeClinicalAssessmentCard =
            (isUserBehavioral() ||
                this.viewOnlyClinicalAssessmentAccess(
                    provider_case_manager,
                    patientDetails.initial_clinical_assessment_complete || false,
                ) ||
                this.viewOnlyClinicalAssessmentAccess(
                    provider_peer,
                    patientDetails.initial_clinical_assessment_complete || false,
                )) &&
            patientDetails[_90_DAY_CARE_PLAN] === "outcome_assessment_complete";

        const showAdmitTo90DayCareCard =
            (isUserBehavioral() || isAuthorized(admin_cse)) &&
            patientDetails.inactivation_type !== PATIENT_INACTIVATION_TYPE.ACCESS_REVOKED &&
            patientDetails[_90_DAY_CARE_PLAN] === "not_assigned";

        return (
            <>
                {this.state.showCarePlanPopup && (
                    <FeatureGate
                        defaultFlagBehavior={true}
                        flagName={EXPERIMENTAL_GOALS_AND_OBJECTIVES}
                        enabled={this.props.privateFlags?.[EXPERIMENTAL_GOALS_AND_OBJECTIVES]}
                        hasLoaded={this.props.privateFlagsHaveLoaded}
                        alternateFlagDisabledBehavior={
                            <CarePlanCreation__EXPERIMENTAL_GOALS_AND_OBJECTIVES__DISABLED
                                close={() => this.setState({ showCarePlanPopup: false })}
                                patientId={this.state.patientId}
                                isPatientInactive={patientDetails["status"] === "INACTIVE"}
                            />
                        }>
                        <CarePlanCreation__EXPERIMENTAL_GOALS_AND_OBJECTIVES__ENABLED
                            close={() => this.setState({ showCarePlanPopup: false })}
                            patientId={this.state.patientId}
                            isPatientInactive={patientDetails["status"] === "INACTIVE"}
                        />
                    </FeatureGate>
                )}

                {show90DayProgress && (
                    <PatientProfile90DayProgress patientDetails={patientDetails} />
                )}

                <PatientReferralInfo
                    isWide
                    referralCode={patientDetails.referral_id}
                    pcpName={patientDetails.pcp_name}
                    referralCreatedDate={patientDetails.referred_date}
                    practiceName={this.state.practiceName}
                    patientDetails={patientDetails}
                />

                <OutreachAddCard
                    profile={this.props.profile}
                    outreach={patientDetails.outreach}
                    submitOutreachAttempt={this.submitOutreachAttempt}
                />

                {patientDetails.outreach && <OutreachEntries outreach={patientDetails.outreach} />}

                {this.state.showAdmitTo90DayCarePopup && (
                    <AdmitPatientPopup
                        hidePopup={() => this.setState({ showAdmitTo90DayCarePopup: false })}
                        admitPatient={this.admitPatient}
                        fullName={this.state.patientName}
                    />
                )}

                {showAdmitTo90DayCareCard && (
                    <AdmitPatient
                        popupHandler={() => this.setState({ showAdmitTo90DayCarePopup: true })}
                    />
                )}

                {showSchedule90DayTaskCard && <Schedule90DayTask patientDetails={patientDetails} />}

                <FeatureGate
                    defaultFlagBehavior={true}
                    flagName={EXPERIMENTAL_GOALS_AND_OBJECTIVES}
                    enabled={this.props.privateFlags?.[EXPERIMENTAL_GOALS_AND_OBJECTIVES]}
                    hasLoaded={this.props.privateFlagsHaveLoaded}
                    alternateFlagDisabledBehavior={
                        <CarePlanCard__EXPERIMENTAL_GOALS_AND_OBJECTIVES__DISABLED
                            patientId={this.state.patientId}
                            patientName={this.state.patientName}
                            showCard={() => this.setState({ showCarePlanPopup: true })}
                        />
                    }>
                    <CarePlanCard__EXPERIMENTAL_GOALS_AND_OBJECTIVES__ENABLED
                        patientId={this.state.patientId}
                        patientName={this.state.patientName}
                        showCard={() => this.setState({ showCarePlanPopup: true })}
                    />
                </FeatureGate>

                {showInitialClinicalAssessmentCard && (
                    <ClinicalAssessmentCard
                        patientId={this.state.patientId}
                        type="initial"
                        done={patientDetails.initial_clinical_assessment_complete || false}
                        patientName={this.state.patientName}
                    />
                )}

                {showOutcomeClinicalAssessmentCard && (
                    <ClinicalAssessmentCard
                        patientId={this.state.patientId}
                        type="outcome"
                        done={patientDetails.outcome_clinical_assessment_complete || false}
                        patientName={this.state.patientName}
                    />
                )}

                <ActivityFeed {...this.props} />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
        privateFlags: state.flags.privateFlags,
        privateFlagsHaveLoaded: state.flags.privateFlagsHaveLoaded,
    };
};

export default connect(mapStateToProps)(withRouter(MemberProfileTimeline));
