// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { useState } from "react";
import CarePlanCard__EXPERIMENTAL_GOALS_AND_OBJECTIVES__DISABLED from "../CarePlanCard__EXPERIMENTAL_GOALS_AND_OBJECTIVES__DISABLED";
import CarePlanCard__EXPERIMENTAL_GOALS_AND_OBJECTIVES__ENABLED from "../CarePlanCard__EXPERIMENTAL_GOALS_AND_OBJECTIVES__ENABLED";
import CareGoalsCard from "../CarePlan/CareGoalsCard/CareGoalsCard";
import CarePlanCreation__EXPERIMENTAL_GOALS_AND_OBJECTIVES__DISABLED from "./CarePlanCreation__EXPERIMENTAL_GOALS_AND_OBJECTIVES__DISABLED";
import CarePlanCreation__EXPERIMENTAL_GOALS_AND_OBJECTIVES__ENABLED from "./CarePlanCreation__EXPERIMENTAL_GOALS_AND_OBJECTIVES__ENABLED";
import withRouter from "react-router-dom/es/withRouter";
import CareTeamCard, { checkIfProviderOnCareTeam } from "../CarePlan/CareTeamCard/CareTeamCard";
import "./style.scss";
import { A5AssessmentCard } from "../CarePlan/A5AssessmentCard/A5AssessmentCard";
import { CarePlanScreenings } from "../CarePlan/CarePlanScreenings";
import { useCareTeam } from "../../../../utils/hooks/useCarePlan";
import FeatureGate from "../../../Common/FeatureGate/FeatureGate";
import {
    EXPERIMENTAL_GOALS_AND_OBJECTIVES,
    EXPERIMENTAL_PT_ARTICULATED_GOALS_STRENGTHS_MEDICAL_CAUTIONS,
} from "../../../../constants/featureFlags";
import { useSelector } from "react-redux";
import DemographicsCard from "../DemographicCard";
import { isUserPCP } from "../../../../redux/actions/auth";
import { PatientGoalsStrengthsCautions } from "../CarePlan/PatientGoalsStrengthsCautions/PatientGoalsStrengthsCautions";
import { getQueryParams } from "../../../../utils/filters";

const MemberProfileCarePlan = (props) => {
    const openLegacyGoals = getQueryParams("openLegacyGoals", props.location.search);

    const profile = useSelector((state) => state.auth.profile);
    const privateFlags = useSelector((state) => state.flags.privateFlags);
    const privateFlagsHaveLoaded = useSelector((state) => state.flags.privateFlagsHaveLoaded);

    const patientDetails = props.patientDetails;

    const [showCarePlanPopup, setShowCarePlanPopup] = useState(openLegacyGoals || false);
    const { careTeamMembers, refreshCareTeam } =
        useCareTeam(props.match.params.patientId, props.data.careTeam, { show_history: true }) ??
        [];

    const isProviderOnCareTeam = checkIfProviderOnCareTeam(profile, careTeamMembers);

    return (
        <>
            {showCarePlanPopup && (
                <FeatureGate
                    defaultFlagBehavior={true}
                    flagName={EXPERIMENTAL_GOALS_AND_OBJECTIVES}
                    enabled={privateFlags?.[EXPERIMENTAL_GOALS_AND_OBJECTIVES]}
                    hasLoaded={privateFlagsHaveLoaded}
                    alternateFlagDisabledBehavior={
                        <CarePlanCreation__EXPERIMENTAL_GOALS_AND_OBJECTIVES__DISABLED
                            close={() => setShowCarePlanPopup(false)}
                            patientId={props.match.params.patientId}
                            isPatientInactive={patientDetails["status"] === "INACTIVE"}
                        />
                    }>
                    <CarePlanCreation__EXPERIMENTAL_GOALS_AND_OBJECTIVES__ENABLED
                        close={() => {
                            const searchParams = new URLSearchParams(props.location.search);
                            searchParams.delete("openLegacyGoals");
                            props.history.push({
                                pathname: location.pathname,
                                search: searchParams.toString(),
                            });
                            setShowCarePlanPopup(false);
                        }}
                        patientId={props.match.params.patientId}
                        isPatientInactive={patientDetails["status"] === "INACTIVE"}
                    />
                </FeatureGate>
            )}

            <div className="MemberProfileCarePlan--container">
                <DemographicsCard
                    patientId={props.match.params.patientId}
                    demographics={{
                        ...props.carePlan?.demographics,
                        pcp_name: patientDetails?.pcp_name,
                        pcp_location: patientDetails?.pcp_location,
                    }}
                />
                <CareTeamCard
                    patientId={props.match.params.patientId}
                    careTeamMembers={careTeamMembers}
                    refreshCareTeam={refreshCareTeam}
                />

                {/* Care plan doesn't exist for unregistered patients, which these components require */}
                {patientDetails?.registration_date && (
                    <>
                        <FeatureGate
                            defaultFlagBehavior={true}
                            flagName={EXPERIMENTAL_GOALS_AND_OBJECTIVES}
                            enabled={privateFlags?.[EXPERIMENTAL_GOALS_AND_OBJECTIVES]}
                            hasLoaded={privateFlagsHaveLoaded}
                            alternateFlagDisabledBehavior={<></>}>
                            <CareGoalsCard
                                patientId={props.match.params.patientId}
                                careTeamMembers={careTeamMembers}
                                patientDetails={props.patientDetails}
                                isProviderOnCareTeam={isProviderOnCareTeam}
                            />
                        </FeatureGate>
                        <FeatureGate
                            defaultFlagBehavior={true}
                            flagName={EXPERIMENTAL_PT_ARTICULATED_GOALS_STRENGTHS_MEDICAL_CAUTIONS}
                            enabled={
                                privateFlags?.[
                                    EXPERIMENTAL_PT_ARTICULATED_GOALS_STRENGTHS_MEDICAL_CAUTIONS
                                ]
                            }
                            hasLoaded={privateFlagsHaveLoaded}
                            alternateFlagDisabledBehavior={<></>}>
                            <PatientGoalsStrengthsCautions
                                patientId={props.match.params.patientId}
                                isProviderOnCareTeam={isProviderOnCareTeam}
                            />
                        </FeatureGate>
                    </>
                )}

                {!isUserPCP() && (
                    <A5AssessmentCard assessmentSummaries={props.carePlan?.assessment_summaries} />
                )}
                <CarePlanScreenings
                    phqSummary={props.carePlan?.assessment_summaries?.phq}
                    gadSummary={props.carePlan?.assessment_summaries?.gad}
                />
                <FeatureGate
                    defaultFlagBehavior={true}
                    flagName={EXPERIMENTAL_GOALS_AND_OBJECTIVES}
                    enabled={privateFlags?.[EXPERIMENTAL_GOALS_AND_OBJECTIVES]}
                    hasLoaded={privateFlagsHaveLoaded}
                    alternateFlagDisabledBehavior={
                        <CarePlanCard__EXPERIMENTAL_GOALS_AND_OBJECTIVES__DISABLED
                            patientId={props.match.params.patientId}
                            showCard={() => setShowCarePlanPopup(true)}
                        />
                    }>
                    <CarePlanCard__EXPERIMENTAL_GOALS_AND_OBJECTIVES__ENABLED
                        patientId={props.match.params.patientId}
                        showCard={() => setShowCarePlanPopup(true)}
                    />
                </FeatureGate>
            </div>
        </>
    );
};

export default withRouter(MemberProfileCarePlan);
