// Copyright aptihealth, inc. 2021 All Rights Reserved

import * as actionTypes from "../actions/actionTypes";

export const isConfigsLoaded = (configs) => {
    return (
        configs["TEENS_AND_KIDS_PROGRAM"] !== undefined &&
        configs["OVERRIDE_RECAPTCHA"] !== undefined &&
        configs["SKIP_PARENT_INSURANCE"] !== undefined &&
        configs["POST_SESSION_SURVEY"] !== undefined &&
        configs["TEENS_AND_KIDS_FLOW"] !== undefined &&
        configs["UNINSURED_PRICES"] !== undefined &&
        configs["PAYMENT_PAYWALL"] !== undefined &&
        configs["NOTE_ADDENDUM"] !== undefined &&
        configs["ENABLED_CARRIER_PAYWALL"] != undefined
    );
};

export const initialConfigsState = {
    TEENS_AND_KIDS_PROGRAM: undefined,
    OVERRIDE_RECAPTCHA: undefined,
    SKIP_PARENT_INSURANCE: undefined,
    POST_SESSION_SURVEY: undefined,
    TEENS_AND_KIDS_FLOW: undefined,
    UNINSURED_PRICES: undefined,
    PAYMENT_PAYWALL: undefined,
    NOTE_ADDENDUM: undefined,
    OVERRIDE_CALL_START_TIME: undefined,
    OVERRIDE_SCHEDULE_NEW_APPOINTMENT: undefined,
    ENABLED_CARRIER_PAYWALL: undefined,
};

const configsLoadedHandler = (state, action) => {
    return {
        ...state,
        ...action.configs,
    };
};

const reducer = (state = initialConfigsState, action) => {
    switch (action.type) {
        case actionTypes.CONFIGS_LOADED:
            return configsLoadedHandler(state, action);
        default:
            return state;
    }
};

export default reducer;
